import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

import "bootstrap/dist/css/bootstrap.min.css"
import "../styles/style.scss"

export default function Home(data) {
  
  //pageImage={data.data.allMarkdownRemark.edges[0].node.frontmatter.pageImage.childImageSharp.fluid}
  //featuredImage={data.data.allMarkdownRemark.edges[0].node.frontmatter.featuredImage.childImageSharp.fluid}
   if (data.data.allMarkdownRemark.edges[0].node.frontmatter.pageImage) {
    var pageImage = data.data.allMarkdownRemark.edges[0].node.frontmatter.pageImage.childImageSharp.fluid
   }
   if (data.data.allMarkdownRemark.edges[0].node.frontmatter.featuredImage) {
    var featuredImage = data.data.allMarkdownRemark.edges[0].node.frontmatter.featuredImage.childImageSharp.fluid
   }
   

  return (
    <Layout pageTitle={data.data.allMarkdownRemark.edges[0].node.frontmatter.title} 
      pageContent={data.data.allMarkdownRemark.edges[0].node.html}
      featuredImage={featuredImage}
      pageImage={pageImage}
      />
  )
}

export const query = graphql`query{
  allMarkdownRemark (filter: {frontmatter:{title: {eq: "Home"}}}) {
    edges {
      node{
        fields {
          slug
        }
      html
      frontmatter {
        title
        position
        pageImage{
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        featuredImage{
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
}
}`